<!--
 * @Author       : Hugo
 * @Date         : 2021-09-29 14:21:06
 * @LastEditTime : 2022-06-13 23:46:29
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/components/PopUp/Reminder.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_reminder_page bbox"
  >
    <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
    >
      <div class="container bbox d-flex flex-column">
        <template
          v-if="turn_status && 0"
        >
          <div class="button previous"></div>
          <div class="button next"></div>
        </template>
        <div class="title_group d-flex align-center un_sel justify-center flex-shrink-0">
          <div class="title">设置提醒</div>
          <div class="close cp"
            @click="doClose();"
          ></div>
        </div>
        <div class="main">
          <main-part
            v-if="type == 'detail'"
            :data="data"
            :conId="conId"
            :taskId="taskId"
            ref="main"
            @handleComplete="handleComplete"
            @handleClose="closeCurrent"
          ></main-part>
          <list-part
            v-if="type == 'list'"
            ref="list"
            :list="list"
            :conId="conId"
            :taskId="taskId"
            @handleOpen="handleOpen"
            @handleDelete="handleDelete"
          ></list-part>
        </div>
        <operating
          @handleSure="doSure"
          :top="top"
          :type="type"
          :data="data"
          @handleNew="newReminder"
          @handleClose="doClose"
          @handleDelete="handleDelete(data)"
        ></operating>

      </div>
    </div>
    <reminder
      v-if="switch_reminder"
      :data="reminder_data"
      type="detail"
      :conId="conId"
      :taskId="taskId"
      :list="list"
      :sub="true"
      @handleClose="closeReminder"
      @handleComplete="handleComplete"
      @handleDelete="handleDelete"
    ></reminder> 
  </div>
</template>

<script>
/**
 * 上级item 增加字段 reminder_custom_index 用于在没有控件id时存储索引
 * 保存提醒时 设置 reminder 的 custom_index
 * 
 * 最后在创建或编辑任务后拿到任务id和控件id 再调用索引
 * 
 */

import Operating from './Reminder/Operating.vue';
// import MainPart from './Reminder/Main.vue';
// import ListPart from './Reminder/List.vue';
export default {
  name: 'reminder',
  components: {
    Operating,
    MainPart: () => import('./Reminder/Main.vue'),
    ListPart: () => import('./Reminder/List.vue'),
  },
  props: {
    conId: {
      type: Number,
      default: null,
    },
    taskId: {
      type: Number,
      default: null,
    },
    list: {
      type: Array,
      default: function(){
        return [];
      }
    },
    type: {
      type: String,
      default: 'detail', // list
    },
    data: {
      type: Object,
      default: null,
    },
    top: {
      type: Boolean,
      default: false,
    },
    sub: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      index: 1,
      switch_reminder: false,
      reminder_data: null,
      // data: {},
      // data: {
      //   taskId: '', //	Y	模板id
      //   conId: null, //	Y	控件id
      //   ptype: 1, //	Y	提醒类型1不重复 2每天 3每周 4每月
      //   remindTime: '', //	Y	提醒时间
      //   toUsers: '', //	Y	提醒用户多个逗号分隔
      //   content: '', //	Y	提醒内容
      //   rangee: '', //	N	提醒范围
      //   startTime: '', //	N	开始时间
      //   endTime: '', //	N	结束时间
      // }
    }
  },
  computed:{
    turn_status(){
      const {sub, list} = this;
      if(sub && list.length > 1){
        return true;
      }
      return false;
    },
  },
  methods: {
    doClose(){
      this.$emit('handleClose');
    },
    closeCurrent(){
      this.doClose();
    },
    changeType(type){
      this.$set(this.data, 'pType', type);
    },
    handleChangeDate(data){

    },
    doSure(){
      this.$refs.main.doSure();
      // this.$api.moduleTask.createReminder();
    },
    init(){
      this.$nextTick(function(){
        const {type} = this;
        if(type == 'detail' && this.$refs.main){
          this.$refs.main.init();
        }
        if(type == 'list' && this.$refs.list){
          // this.$refs.list.init();
        }
      })

    },
    newReminder(){
      this.$set(this, 'reminder_data', null);
      this.openReminder();
    },  
    handleOpen(item){
      // console.log(item);
      this.$set(this, 'reminder_data', item);
      this.openReminder();
    },
    openReminder(){
      this.switch_reminder = true;
    },
    closeReminder(){
      this.switch_reminder = false;
    },
    // handleCreate(res){
    //   this.$emit('handleCreate', res);
    // },
    // hadnleEdit(res){
    //   this.$emit('handleEdit', res);
    // },
    handleComplete(res){
      this.$emit('handleComplete', res);
    },
    handleDelete(data){
      this.$emit('handleDelete', data);
      
    }
  },
  mounted(){
    const zindex = this.$utils.getZIndex();
    this.index = zindex;
    this.init();
  },
}
</script>
<style lang="scss" scoped>
.components_pop_up_reminder_page{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .container{
    // width: 100%;
    // height: 100%;
    max-width: 80%;
    max-height: 90%;
    border-radius: 4px;
    box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color:#fff;
    padding-bottom: 20px;
    position: relative;
    z-index: 2;
  }
  .button{
    @include background;
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    &.previous{
      left: -100px;
      background-image: url(~@/assets/images/reminder/previous.png);
    }
    &.next{
      right: -100px;
      background-image: url(~@/assets/images/reminder/next.png);
    }
  }
  .title_group{
    height: 60px;
    position: relative;
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px 4px 0 0;
    .title{
      font-size: 16px;
      color: #333333;
    }
    .close{
      @include background(12px, 12px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/close.png);
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
  .main{
    
    font-size: 14px;
    color: #333333;
  }
  .mb-24{
    margin-bottom: 24px;
  }
}
</style>