var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_pop_up_reminder_page bbox" },
    [
      _c(
        "div",
        {
          staticClass: "mask d-flex align-center justify-center",
          style: { zIndex: _vm.index },
        },
        [
          _c(
            "div",
            { staticClass: "container bbox d-flex flex-column" },
            [
              _vm.turn_status && 0
                ? [
                    _c("div", { staticClass: "button previous" }),
                    _c("div", { staticClass: "button next" }),
                  ]
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "title_group d-flex align-center un_sel justify-center flex-shrink-0",
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("设置提醒")]),
                  _c("div", {
                    staticClass: "close cp",
                    on: {
                      click: function ($event) {
                        return _vm.doClose()
                      },
                    },
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "main" },
                [
                  _vm.type == "detail"
                    ? _c("main-part", {
                        ref: "main",
                        attrs: {
                          data: _vm.data,
                          conId: _vm.conId,
                          taskId: _vm.taskId,
                        },
                        on: {
                          handleComplete: _vm.handleComplete,
                          handleClose: _vm.closeCurrent,
                        },
                      })
                    : _vm._e(),
                  _vm.type == "list"
                    ? _c("list-part", {
                        ref: "list",
                        attrs: {
                          list: _vm.list,
                          conId: _vm.conId,
                          taskId: _vm.taskId,
                        },
                        on: {
                          handleOpen: _vm.handleOpen,
                          handleDelete: _vm.handleDelete,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("operating", {
                attrs: { top: _vm.top, type: _vm.type, data: _vm.data },
                on: {
                  handleSure: _vm.doSure,
                  handleNew: _vm.newReminder,
                  handleClose: _vm.doClose,
                  handleDelete: function ($event) {
                    return _vm.handleDelete(_vm.data)
                  },
                },
              }),
            ],
            2
          ),
        ]
      ),
      _vm.switch_reminder
        ? _c("reminder", {
            attrs: {
              data: _vm.reminder_data,
              type: "detail",
              conId: _vm.conId,
              taskId: _vm.taskId,
              list: _vm.list,
              sub: true,
            },
            on: {
              handleClose: _vm.closeReminder,
              handleComplete: _vm.handleComplete,
              handleDelete: _vm.handleDelete,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }