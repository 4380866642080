var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_reminder_operating_page d-flex align-center justify-space-between bbox",
    },
    [
      _c("div", { staticClass: "other" }, [
        _vm.top
          ? _c(
              "div",
              {
                staticClass: "new_group d-flex align-center cp ul_sel",
                on: {
                  click: function ($event) {
                    return _vm.newReminder()
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require("@/assets/images/reminder/add_round.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "text" }, [
                  _vm._v("保存并添加新提醒"),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "button_group d-flex align-center" }, [
        _vm.type === "detail" &&
        _vm.data &&
        (_vm.data.id ||
          (_vm.data.custom_index != "" && _vm.data.custom_index != null))
          ? _c(
              "div",
              {
                staticClass: "button btn_warn mr-10",
                on: {
                  click: function ($event) {
                    return _vm.doDelete()
                  },
                },
              },
              [_vm._v("删除提醒")]
            )
          : _vm._e(),
        _vm.type === "detail"
          ? _c(
              "div",
              {
                staticClass: "button btn_dark",
                on: {
                  click: function ($event) {
                    return _vm.doSure()
                  },
                },
              },
              [_vm._v("保存并完成设置")]
            )
          : _vm._e(),
        _vm.type === "list"
          ? _c(
              "div",
              {
                staticClass: "button btn_light",
                on: {
                  click: function ($event) {
                    return _vm.doClose()
                  },
                },
              },
              [_vm._v("关闭")]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }