<template>
  <div class="components_pop_up_reminder_operating_page d-flex align-center justify-space-between bbox">
    <div class="other">
      <div class="new_group d-flex align-center cp ul_sel"
        v-if="top"
        @click="newReminder();"
      >
        <img 
        
          src="~@/assets/images/reminder/add_round.png" alt="" class="icon">
        <div class="text">保存并添加新提醒</div>
      </div>
    </div>
    <div class="button_group d-flex align-center">
      <div class="button btn_warn mr-10"
        v-if="type === 'detail' && data && (data.id || (data.custom_index != '' && data.custom_index != null) )"
        @click="doDelete()"
      >删除提醒</div>
      <div class="button btn_dark"
        v-if="type === 'detail'"
        @click="doSure()"
      >保存并完成设置</div>
      <div class="button btn_light"
        v-if="type === 'list'"
        @click="doClose()"
      >关闭</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'detail', // list
    },
    data: {
      type: Object,
      default: null, 
    },
  },
  methods:{
    doSure(){
      this.$emit('handleSure');
    },
    newReminder(){
      this.$emit('handleNew');
    },
    doClose(){
      this.$emit('handleClose');
    },
    doDelete(){
      this.$openConfirm({
        title:'确定要删除该提醒?',
        desc:'该操作无法撤销',
        sure:'',
        cancel:'',
        onSure: async ()=>{
          this.$emit('handleDelete');
          this.doClose();
        },
        onCancel:()=>{},
      })
    }
  },
  mounted(){
    // console.log(this.data);
  }
}
</script>
<style lang="scss" scoped>
.components_pop_up_reminder_operating_page{
  width: 100%;
  padding: 0 40px;
  .button{
    padding: 0 26px;
    line-height: 40px;
    border-radius: 4px;
    font-size: 14px;
  }
  .btn_warn{
    border:1px solid #C84B33;
    color: #C84B33;
    line-height: 38px;
  }
  .button_group{
  }
  .mr-10{
    margin-right: 10px;
  }
  .new_group{
    .text{
      font-size: 14px;
      color: $main_blue;
      padding-left: 10px;
    }
  }
}
</style>